import { IconCheck } from "@tabler/icons-react"
import { roundOffNumber } from "../../../../utils"
import { apxTheme } from "../../../ApplicationFrame/styling/CustomCSS"
import HStack from "../../../ReusableComponents/HStack"
import {
  DEFAULT_OPERATOR_OPTIONS,
  EQUALITY_OPERATOR_OPTIONS,
  NUMBER_OPERATOR_OPTIONS,
} from "../../common/components/campaigncreator/components/constants"
import OverviewImg from "./assets/preview/aggregate.svg"
import ActiveUserImg from "./assets/preview/bar.svg"
import AdvancedPiechartImg from "./assets/preview/donut.svg"
import DeviceUsageImg from "./assets/preview/pie_chart.svg"
import EventTrendImg from "./assets/preview/single_trend.svg"
import StackBarImg from "./assets/preview/stack_bar.svg"
import TableImg from "./assets/preview/table.svg"

export const WIDGET_TYPES = {
  FREQUENCY: "Frequency",
  STACKED_FREQUENCY: "Stacked Frequency Chart",
  AGGREGATES: "Aggregates",
  TRENDS: "Trends",
  PIECHARTS: "Pie Chart",
  TABLE: "Table",
  ADVANCED_PICHART: "Advanced Pie Chart",
}

export const INITIAL_VISUALIZATION = [
  {
    widget_name: WIDGET_TYPES.FREQUENCY,
    description:
      "Visualizes how often events occur, highlighting patterns and anomalies in the dataset.",
    type: WIDGET_TYPES.FREQUENCY,
  },
  {
    widget_name: WIDGET_TYPES.STACKED_FREQUENCY,
    description:
      "Visualizes how often events occur, highlighting patterns and anomalies in the dataset with stacked bar.",
    type: WIDGET_TYPES.STACKED_FREQUENCY,
  },
  {
    widget_name: WIDGET_TYPES.AGGREGATES,
    description:
      "Summarizes data by categories, showing totals, averages, and other key metrics.",
    type: WIDGET_TYPES.AGGREGATES,
  },
  {
    widget_name: WIDGET_TYPES.TRENDS,
    description:
      "Displays changes over time, helping to identify patterns and forecast future values.",
    type: WIDGET_TYPES.TRENDS,
  },
  {
    widget_name: WIDGET_TYPES.PIECHARTS,
    description:
      "Breaks down data into segments, illustrating each category's contribution to the whole.",
    type: WIDGET_TYPES.PIECHARTS,
  },
  {
    widget_name: WIDGET_TYPES.TABLE,
    description: "Analyzes the frequency of events and highlights anomalies.",
    type: WIDGET_TYPES.TABLE,
  },
  {
    widget_name: WIDGET_TYPES.ADVANCED_PICHART,
    description:
      "Breaks down data into segments, illustrating each category's contribution to the whole. perform quiries",
    type: WIDGET_TYPES.ADVANCED_PICHART,
  },
]

export const getImageBasedOnWidget = (widget_name) => {
  switch (widget_name) {
    case WIDGET_TYPES.FREQUENCY: {
      return (
        <img
          src={ActiveUserImg}
          alt={widget_name}
        />
      )
    }
    case WIDGET_TYPES.AGGREGATES: {
      return (
        <img
          src={OverviewImg}
          alt={widget_name}
        />
      )
    }
    case WIDGET_TYPES.TRENDS: {
      return (
        <img
          src={EventTrendImg}
          alt={widget_name}
        />
      )
    }

    case WIDGET_TYPES.PIECHARTS: {
      return (
        <img
          src={DeviceUsageImg}
          alt={widget_name}
        />
      )
    }
    case WIDGET_TYPES.TABLE: {
      return (
        <img
          src={TableImg}
          alt={widget_name}
        />
      )
    }
    case WIDGET_TYPES.ADVANCED_PICHART: {
      return (
        <img
          src={AdvancedPiechartImg}
          alt={widget_name}
        />
      )
    }
    case WIDGET_TYPES.STACKED_FREQUENCY: {
      return (
        <img
          src={StackBarImg}
          alt={widget_name}
        />
      )
    }

    default:
      return (
        <img
          src={OverviewImg}
          alt="nothing"
        />
      )
  }
}

export const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 54,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    minWidth: 200,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    // ...(state.hasValue && { border: "none" }),
    // ...(!state.hasValue && {
    //   boxShadow: `0 0 0 1.5px ${state.hasValue ? "#1387E8" : "#D64A40"}`,
    // }),
    borderColor: "rgba(0,40,69, 0.54)",
    borderRadius: 12,
    backgroundColor: "#F8FAFC",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#94A3B8",
    fontWeight: 500,
    fontSize: 15,
    padding: 5,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#F8F8F8" : "transparent",
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
    cursor: "pointer",
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 12,
    padding: 12,
    zIndex: 500,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
    zIndex: 99999,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(229, 59, 59, 1)",
    },
  }),
}

export const DATA_TYPE_FOR_BI_DASHBOARDS = {
  STRING: "String",
  INTEGER: "Integer",
  FLOAT: "Float",
  DATETIME: "Datetime",
  DATE: "date",
}

export function getDataTypeBasedOnServerDataType(dataType) {
  switch (dataType) {
    case "string":
      return {
        label: DATA_TYPE_FOR_BI_DASHBOARDS.STRING,
        value: "string",
      }
    case "float":
      return {
        label: DATA_TYPE_FOR_BI_DASHBOARDS.FLOAT,
        value: "float",
      }
    case "int":
      return {
        label: DATA_TYPE_FOR_BI_DASHBOARDS.INTEGER,
        value: "int",
      }
    case "datetime":
      return {
        label: DATA_TYPE_FOR_BI_DASHBOARDS.DATETIME,
        value: "datetime",
      }
    case "date":
      return {
        label: DATA_TYPE_FOR_BI_DASHBOARDS.DATE,
        value: "date",
      }

    default:
      return ""
  }
}

export const getOptionsForTypeForBiDashboard = (type) => {
  switch (type) {
    case "string":
      return [...EQUALITY_OPERATOR_OPTIONS, { label: "RegEx", value: "REGEX" }]
    case "float":
    case "int":
    case "datetime":
    case "date":
      return NUMBER_OPERATOR_OPTIONS
    default:
      return DEFAULT_OPERATOR_OPTIONS
  }
}

export const CUSTOM_FORMULAS_OPERATOR_ENUM = [
  {
    label: "+",
    value: "+",
  },
  {
    label: "-",
    value: "-",
  },
  {
    label: "*",
    value: "*",
  },
  {
    label: "/",
    value: "/",
  },
]

export const NUMBERED_FORMULAS_OPERATOR_ENUM = [
  {
    label: "+",
    value: "+",
  },
  {
    label: "-",
    value: "-",
  },
  {
    label: "*",
    value: "*",
  },
  {
    label: "/",
    value: "/",
  },
  {
    label: "max",
    value: "max",
  },
  {
    label: "min",
    value: "min",
  },
  {
    label: "avg",
    value: "avg",
  },
  {
    label: "%",
    value: "%",
  },
]

export const DATE_FORMULAS_OPERATOR_ENUM = [
  {
    label: "max",
    value: "max",
  },
  {
    label: "min",
    value: "min",
  },
  {
    label: "-",
    value: "-",
  },
]

export const NUMERIC_TRANSFORMATION = [
  {
    label: "Absolute",
    value: "abs",
  },
  {
    label: "Number",
    value: "toInt",
  },
  {
    label: "Seconds to timestamp",
    value: "SecondsToTimestamp",
  },
  {
    label: "Millis to timestamp",
    value: "MillisToTimestamp",
  },
]

export const DATE_TRANSFORMATION = [
  {
    label: "InTimestamp",
    value: "toTimeStamp",
  },
  {
    label: "InDays",
    value: "toWeekDay",
  },
  {
    label: "InHour",
    value: "toUTCHour",
  },
  {
    label: "InMonth",
    value: "toMonth",
  },
  {
    label: "InDate",
    value: "toDate",
  },
]

export const TOTAL_TRANSFORMATION = [
  {
    label: "Seconds to Timestamp",
    value: "secondsToTimestamp",
  },
  {
    label: "Millis to Timestamp",
    value: "millisToTimestamp",
  },
  {
    label: "In Timestamp",
    value: "toTimeStamp",
  },
  {
    label: "In Days",
    value: "toWeekDay",
  },
  {
    label: "In Hour",
    value: "toUTCHour",
  },
  {
    label: "In Month",
    value: "toMonth",
  },
  {
    label: "In Date",
    value: "toDate",
  },
]

function numberToAlphabet(num) {
  // Assuming num is between 0 and 25
  return String.fromCharCode(65 + (num % 26))
}

export const convertNumbersToAlphabets = (str) => {
  const numbers = str.match(/\d+/g)
  if (!numbers) return str // If there are no numbers, return the original string
  let result = str
  numbers.forEach((num, index) => {
    const alphabet = numberToAlphabet(parseInt(num))
    result = result.replace(new RegExp(num, "g"), alphabet)
  })
  return result
}

function combineGraphData(graphDataArray) {
  const combinedData = []
  // Initialize combinedData with data from the first graph
  combinedData.push(...graphDataArray[0].data)

  // Iterate over the remaining graphs and combine their data
  for (let i = 1; i < graphDataArray.length; i++) {
    const currentGraphData = graphDataArray[i].data
    // Iterate over each data point in the current graph and add it to combinedData
    for (let j = 0; j < currentGraphData.length; j++) {
      // Check if the key already exists in combinedData
      const existingDataIndex = combinedData.findIndex(
        (item) => item.key === currentGraphData[j].key,
      )
      if (existingDataIndex !== -1) {
        // If the key exists, add the value to the existing data
        combinedData[existingDataIndex].value += currentGraphData[j].value
      } else {
        // If the key doesn't exist, add it to combinedData
        combinedData.push(currentGraphData[j])
      }
    }
  }
  return combinedData
}

export const extractAndCombineData = (dataArray) => {
  const extractedData = dataArray.slice(0, 5) // Extract data for first 5 graphs

  const combinedData = combineGraphData(dataArray.slice(5)) // Combine data for remaining graphs
  return [
    ...extractedData,
    { graph: "5+", data: combinedData, index: dataArray[0]?.index, err: "" },
  ]
}

export const COLORS_FOR_GRAPH = {
  // option_1: ["#C084FC", "#2563EB", "#FB923C", "#22C55E", "#C002A2", "#0EA5E9"],
  // option_2: ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A133FF", "#57FFA1"],
  // option_3: ["#FFB6C1", "#20B2AA", "#778899", "#FFD700", "#ADFF2F", "#FF4500"],
  // option_4: ["#6A0DAD", "#00FA9A", "#FF6347", "#4682B4", "#D2691E", "#9ACD32"],
  // option_5: ["#1E90FF", "#FF1493", "#32CD32", "#FFD700", "#8A2BE2", "#FF4500"],
  // option_6: ["#FF7F50", "#6495ED", "#DC143C", "#00CED1", "#8B008B", "#FFA07A"],
  // option_7: ["#7FFF00", "#D2691E", "#FF69B4", "#1E90FF", "#FFD700", "#ADFF2F"],
  // option_8: ["#E9967A", "#8FBC8F", "#483D8B", "#2E8B57", "#DAA520", "#FF4500"],
  // option_9: ["#FF00FF", "#00FF00", "#0000FF", "#FFFF00", "#FF0000", "#00FFFF"],
  // option_10: ["#B22222", "#FFA500", "#DAA520", "#32CD32", "#4169E1", "#8A2BE2"],
  "Sequential-->Rose": [
    "#fff1f2",
    "#ffe4e6",
    "#fecdd3",
    "#fda4af",
    "#fb7185",
    "#f43f5e",
    "#e11d48",
    "#be123c",
    "#9f1239",
    "#881337",
    "#4c0519",
  ],
  "Sequential-->Blue": [
    "#eff6ff",
    "#dbeafe",
    "#bfdbfe",
    "#93c5fd",
    "#60a5fa",
    "#3b82f6",
    "#2563eb",
    "#1d4ed8",
    "#1e40af",
    "#1e3a8a",
    "#172554",
  ],
  "Sequential-->Green": [
    "#f0fdf4",
    "#dcfce7",
    "#bbf7d0",
    "#86efac",
    "#4ade80",
    "#22c55e",
    "#16a34a",
    "#15803d",
    "#166534",
    "#14532d",
    "#052e16",
  ],
  "Sequential-->Orange": [
    "#fff7ed",
    "#ffedd5",
    "#fed7aa",
    "#fdba74",
    "#fb923c",
    "#f97316",
    "#ea580c",
    "#c2410c",
    "#9a3412",
    "#7c2d12",
    "#431407",
  ],
  "Sequential-->Gray": [
    "#f8fafc",
    "#f1f5f9",
    "#e2e8f0",
    "#cbd5e1",
    "#94a3b8",
    "#64748b",
    "#475569",
    "#334155",
    "#1e293b",
    "#0f172a",
    "#020617",
  ],
  "Diverging:Green-->White": [
    "#0a2d2e",
    "#1c4e4f",
    "#436e6f",
    "#6a8e8f",
    "#879693",
    "#a49e97",
    "#deae9f",
    "#efd7cf",
    "#f7ebe7",
    "#ffffff",
  ],
  "Diverging:DarkGreen-->Yellow": [
    "#00202e",
    "#003f5c",
    "#2c4875",
    "#8a508f",
    "#bc5090",
    "#ff6361",
    "#ff8531",
    "#ffa600",
    "#ffd380",
  ],
  rainbow_of_10: [
    "#f65356",
    "#fb8066",
    "#fea671",
    "#fec979",
    "#feea80",
    "#d7e586",
    "#a5de94",
    "#5daad8",
    "#8187c7",
    "#c56bba",
  ],
  rainbow_of_5: ["#ff595e", "#ffca3a", "#8ac926", "#1982c4", "#6a4c93"],
  orange_shade_of_10: [
    "#c7522a",
    "#cb6036",
    "#cf6e41",
    "#d68a58",
    "#dea66f",
    "#e5c185",
    "#f0daa5",
    "#fbf2c4",
    "#dae0b8",
    "#b8cdab",
  ],

  Random_20: [
    "#1abc9c",
    "#16a085",
    "#2ecc71",
    "#27ae60",
    "#3498db",
    "#2980b9",
    "#9b59b6",
    "#8e44ad",
    "#34495e",
    "#2c3e50",
    "#f1c40f",
    "#f39c12",
    "#e67e22",
    "#d35400",
    "#e74c3c",
    "#c0392b",
    "#ecf0f1",
    "#bdc3c7",
    "#95a5a6",
    "#7f8c8d",
  ],
  "Diverging:DarkPurple-->Blue-->Red": [
    "#413344",
    "#614c65",
    "#806485",
    "#936397",
    "#a662a8",
    "#664972",
    "#463c57",
    "#6e8da9",
    "#91bcdd",
    "#567d99",
    "#395e77",
    "#305662",
    "#264d4d",
    "#315c45",
    "#8a9a65",
    "#b6b975",
    "#b65d54",
    "#b60033",
    "#98062d",
    "#800022",
  ],
  ColorsForStackedBar: ["#1982c4", "#7cb5ec", "#91e8e1", "#f39c12", "#6a4c93"],
}

export const HighchartsSingleColorArray = [
  { label: "Sky Blue", value: "#7cb5ec" },
  { label: "Charcoal", value: "#434348" },
  { label: "Light Green", value: "#90ed7d" },
  { label: "Peach", value: "#f7a35c" },
  { label: "Lavender", value: "#8085e9" },
  { label: "Pink", value: "#f15c80" },
  { label: "Gold", value: "#e4d354" },
  { label: "Teal", value: "#2b908f" },
  { label: "Coral", value: "#f45b5b" },
  { label: "Aqua", value: "#91e8e1" },
]

export function Option({ innerProps, isDisabled, ...props }) {
  return (
    <div
      {...innerProps}
      {...props}
      style={{
        ...innerProps.style,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: isDisabled ? 0.5 : 1,
        backgroundColor: props.isFocused
          ? "#F8F8F8"
          : props.isSelected
            ? apxTheme.palette.grey[100]
            : "transparent",
        color: props.isFocused
          ? apxTheme.palette.primary.main
          : apxTheme.palette.text.primary,
        cursor: isDisabled ? "not-allowed" : "pointer",
        pointerEvents: isDisabled ? "none" : "auto",
        fontSize: 13,
        fontWeight: 600,
        borderRadius: 12,
        padding: 12,
        zIndex: 888,
        ...(props.data?.isButton && {
          backgroundColor: props.isFocused
            ? apxTheme.palette.grey[800]
            : apxTheme.palette.grey[900],
          transition: "background-color 0.2s ease-in-out",
          color: apxTheme.palette.common.white,
          fontSize: 14,
          flex: 1,
          fontWeight: 900,
          position: "sticky",
          bottom: 0,
          insetInline: 0,
          padding: 14,
          borderRadius: 8,
        }),
      }}
    >
      <HStack
        style={{ width: "93%" }}
        spacing={12}
        justify={"space-between"}
      >
        {props.data?.label}
        <HStack>
          {COLORS_FOR_GRAPH[props.data?.label].map((color) => (
            <div
              key={color}
              style={{
                backgroundColor: color,
                width: "10px",
                height: "10px",
              }}
            />
          ))}
        </HStack>
      </HStack>

      {props.isSelected && !props.data?.isButton ? (
        <IconCheck
          color={apxTheme.palette.success.main}
          size={19}
        />
      ) : (
        <IconCheck
          color={"transparent"}
          size={19}
        />
      )}
    </div>
  )
}

export function OptionForTrend({ innerProps, isDisabled, ...props }) {
  return (
    <div
      {...innerProps}
      {...props}
      style={{
        ...innerProps.style,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        opacity: isDisabled ? 0.5 : 1,
        backgroundColor: props.isFocused
          ? "#F8F8F8"
          : props.isSelected
            ? apxTheme.palette.grey[100]
            : "transparent",
        color: props.isFocused
          ? apxTheme.palette.primary.main
          : apxTheme.palette.text.primary,
        cursor: isDisabled ? "not-allowed" : "pointer",
        pointerEvents: isDisabled ? "none" : "auto",
        fontSize: 13,
        fontWeight: 600,
        borderRadius: 12,
        padding: 12,
        zIndex: 999,
        ...(props.data?.isButton && {
          backgroundColor: props.isFocused
            ? apxTheme.palette.grey[800]
            : apxTheme.palette.grey[900],
          transition: "background-color 0.2s ease-in-out",
          color: apxTheme.palette.common.white,
          fontSize: 14,
          flex: 1,
          fontWeight: 900,
          position: "sticky",
          bottom: 0,
          insetInline: 0,
          padding: 14,
          borderRadius: 8,
        }),
      }}
    >
      <HStack
        style={{ width: "93%" }}
        spacing={12}
        justify={"space-between"}
      >
        {props.data?.label}
        <HStack>
          <div
            style={{
              backgroundColor:
                HighchartsSingleColorArray.map((c) => ({
                  ...c,
                  colorCode: c.value,
                })).find((c) => c.label === props.data?.label)?.colorCode ??
                "red",
              width: "10px",
              height: "10px",
            }}
          />
        </HStack>
      </HStack>

      {props.isSelected && !props.data?.isButton ? (
        <IconCheck
          color={apxTheme.palette.success.main}
          size={19}
        />
      ) : (
        <IconCheck
          color={"transparent"}
          size={19}
        />
      )}
    </div>
  )
}
export const PIECHART_TYPES = [
  {
    label: "Normal Pie Chart",
    value: "normal_pie_chart",
  },
  {
    label: "Donut",
    value: "donut",
  },
]

export const TRENDS_GRAPH_TYPES = [
  {
    label: "Area Graph",
    value: "area",
  },
  {
    label: "Line Graph",
    value: "line",
  },
]

export const trendValueTypes = [
  {
    label: "average",
    value: "average",
  },
  {
    label: "median",
    value: "median",
  },
  {
    label: "min",
    value: "min",
  },
  {
    label: "max",
    value: "max",
  },
  {
    label: "sum",
    value: "sum",
  },
  {
    label: "start",
    value: "start",
  },
  {
    label: "latest",
    value: "latest",
  },
]

export const median = (arr) => {
  const sortedArr = [...arr]
  sortedArr.sort((a, b) => a - b)
  const mid = Math.floor(sortedArr.length / 2)
  return sortedArr.length % 2 !== 0
    ? sortedArr[mid]
    : (sortedArr[mid - 1] + sortedArr[mid]) / 2
}

export const unitOptions = [
  {
    label: "Time",
    options: [
      {
        label: "Second=sec",
        value: "sec",
      },
      {
        label: "Minutes=mins",
        value: "mins",
      },
      {
        label: "Hours=hrs",
        value: "hrs",
      },

      {
        label: "Day=days",
        value: "days",
      },
      {
        label: "Week=week",
        value: "week",
      },
    ],
  },
  {
    label: "Numbers",
    options: [
      {
        label: "Thousand=K",
        value: "K",
      },
      {
        label: "Lakh=L",
        value: "L",
      },
      {
        label: "Million=M",
        value: "M",
      },
      {
        label: "Billion=B",
        value: "B",
      },
    ],
  },
]

export const finalTransformationOptions = [
  {
    label: "secondsToTimestamp",
    value: "secondsToTimestamp",
  },
  {
    label: "millisToTimestamp",
    value: "millisToTimestamp",
  },
]

export function formatNumberWithUnit(anyNumber, fixed = 2) {
  if (isNaN(anyNumber)) return anyNumber
  const number = roundOffNumber(anyNumber) //rounding number to 2 decimals
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed)
    : // Six Zeroes for Millions
      Math.abs(Number(number)) >= 1.0e6
      ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed)
      : // Three Zeroes for Thousands
        Math.abs(Number(number)) >= 1.0e3
        ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed)
        : Math.abs(Number(number))
}

export function formatNumberBigNumbers(num, defaultUnit = "") {
  let number = Number(num)
  let absNum = Math.abs(number)

  if (String(number).length > 6) {
    if (
      defaultUnit !== "K" &&
      defaultUnit !== "M" &&
      defaultUnit !== "L" &&
      defaultUnit !== "B"
    ) {
      if (absNum >= 1e12) {
        number = number / 1e12
        return number.toFixed(1) + "T"
      } else if (absNum >= 1e9) {
        number = number / 1e9
        return number.toFixed(1) + "B"
      } else if (absNum >= 1e6) {
        number = number / 1e6
        return number.toFixed(1) + "M"
      } else if (absNum >= 1e3) {
        number = number / 1e3
        return number.toFixed(1) + "K"
      }
    } else {
      if (absNum >= 1e12) {
        number = number / 1e12
        return number.toFixed(1)
      } else if (absNum >= 1e9) {
        number = number / 1e9
        return number.toFixed(1)
      } else if (absNum >= 1e6) {
        number = number / 1e6
        return number.toFixed(1)
      } else if (absNum >= 1e3) {
        number = number / 1e3
        return number.toFixed(1)
      }
    }
  }

  return number
}
